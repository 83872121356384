import bg from "../../../assets/back.svg";
import styled from "styled-components";

export const ScCard = styled.div`
    background-color: white;
    width: 219px;
    height: 322.74px;
    border-radius: 14px;
    padding: 20px 25px;
    text-align: center;
    position: relative;
    z-index: 2;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 145px;
        background: url(${bg}) center / cover no-repeat;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        z-index: -1;
    }

    p {
        margin: 0;
    }

    .card__footballer {
        width: 169px;
        height: 226px;
        object-fit: cover;
        position: relative;
        z-index: 1;
    }

    .card__shadow {
        position: absolute;
        width: 162.7px;
        height: 36.74px;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0%);
        opacity: 0.2;
    }

    .card__title {
        margin-bottom: 15px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 19px;
        line-height: 23px;
    }

    .card__type {
        display: inline-block;
        color: #082148;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 19.8px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }

    .card__next {
        position: absolute;
        bottom: -47px;
        width: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        font-family: "Montserrat", sans-serif;
        color: #fdfffd;
    }

    @media (max-width: 500px) {
        width: 144.96px;
        height: 260px;
        padding: 10px;
        border-radius: 9px;
        &::after {
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;
        }

        .card__title {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 8px;
        }

        .card__type {
            height: auto;
            padding: 2px 7px;
            margin-bottom: 8px;
        }

        .card__footballer {
            width: 100.83px;
            height: 170px;
        }

        .card__shadow {
            width: 97.07px;
            height: 21.92px;
            bottom: 13px;
        }
    }
`;
