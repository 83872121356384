import "./style.scss";
import { Modal } from "antd";

interface Modal {
  isOpen: boolean;
  onClose: () => void;
  children: any;
}

export default function LoginModal({ isOpen, onClose, children }: Modal) {
  return (
    <Modal
      title="Sign Up | Log In"
      open={isOpen}
      onOk={() => console.log("ok")}
      onCancel={onClose}
      className={"sign"}
      wrapClassName={"sign-wrapper"}
      width={414}
      cancelButtonProps={{ className: "sign-button sign-button_cancel" }}
      okButtonProps={{ className: "sign-button sign-button_ok" }}
      bodyStyle={{ background: "#082148" }}
      style={{ background: "#082148", borderRadius: "32px" }}
      footer={null}
    >
      {children}
    </Modal>
  );
}
