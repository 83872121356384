import { isValidSkinColor, saveToExportQueue } from "../../config/requests";
import {
  BLOCKCHAIN_QUERY_PARAM,
  MINTER_ADDRESS,
  TON_CENTER_API_KEY,
  TON_CENTER_HOST,
} from "../../config/variables";
import { NotValidError } from "../../errors/error";
import { ExportQueue, Footballer } from "../../models/model";
import { generatePayload } from "../../nft-transaction";
import { ButtonMint } from "../TxForm/ButtonMint";
import { ScMint } from "../TxForm/mintyle";
import { SendTransactionRequest, UserRejectsError } from "@tonconnect/sdk";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Address } from "ton";
import TonWeb from "tonweb";

const tonWeb = new TonWeb(
  new TonWeb.HttpProvider(TON_CENTER_HOST, {
    apiKey: TON_CENTER_API_KEY,
  })
);

interface ITonMintButton {
  color: string;
}

export default function TonMintButton({ color }: ITonMintButton) {
  const [price, setPrice] = useState<string>("450000000");
  const [tx, setTx] = useState<SendTransactionRequest | null>(null);
  const [sendTo, setSendTo] = useState<string>("");
  const [tonConnectUI] = useTonConnectUI();
  const address = useTonAddress(false);

  const removeTxMessage = useCallback(() => {
    console.log("removeTxMessage", tx?.messages.length === 2);
    if (tx && tx.messages.length === 2) {
      setTx(
        (value) =>
          ({
            ...value,
            messages: value!.messages.slice(1),
          } as SendTransactionRequest)
      );
    }
  }, [tx]);

  const handleMint = async () => {
    if (!tx) return;
    try {
      const footballer = new Footballer();
      footballer.skinColor = color;

      const queue = new ExportQueue();
      queue.skinColor = footballer.skinColor;
      queue.addressId = address;
      queue.operation = "mint";
      queue.blockchain = "TON";

      await validateMint(queue);
      const result = await tonConnectUI.sendTransaction(tx);
      const response = await saveToExportQueue(queue);

      console.log(response);

      notification.success({
        message: "Successful transaction",
        description:
          "You transaction was successfully sent. Please wait until the transaction is included to the TON blockchain. Player NFT minted to your wallet.",
        duration: 10,
      });

      console.log(`Send tx result: ${JSON.stringify(result)}`);
    } catch (e) {
      let message = "Send transaction error";
      let description = "";

      if (typeof e === "object" && e instanceof UserRejectsError) {
        message = "You rejected the transaction";
        description =
          "Please try again and confirm transaction in your wallet.";
      }

      if (typeof e === "object" && e instanceof NotValidError) {
        message = "Color is not available";
        description = "Please try again and choose another color.";
      }

      notification.error({
        message,
        description,
      });
      console.error(e);
    }
  };

  useEffect(() => {
    if (tonConnectUI.connected) {
      const walletAddr = new TonWeb.utils.Address(address);
      const minterAddr = new TonWeb.utils.Address(MINTER_ADDRESS);

      const fetchData = async () => {
        const userAddrCell = new tonWeb.boc.Cell();
        userAddrCell.bits.writeAddress(walletAddr);
        const userAddrSlice = tonWeb.utils.bytesToBase64(
          await userAddrCell.toBoc(false)
        );
        const priceFor = await tonWeb.provider.call(
          minterAddr.toString(),
          "get_price_for",
          [["tvm.Slice", userAddrSlice]]
        );
        const priceForInt = parseInt(priceFor.stack[0][1], 16);

        setPrice(String(priceForInt));

        const tx = {
          validUntil: Date.now() + 1000000,
          messages: [
            {
              address: MINTER_ADDRESS,
              amount: String(priceForInt),
            },
          ],
        };
        setTx(tx);
      };
      fetchData();
    } else {
      setTx(null);
      setSendTo("");
    }
  }, [address]);

  useEffect(() => {
    if (sendTo) {
      try {
        Address.parseFriendly(sendTo);
      } catch (e) {
        removeTxMessage();
        return;
      }

      const payload = generatePayload(sendTo);
      setTx(
        (value) =>
          ({
            ...value,
            messages: [...value!.messages].concat({
              address: value!.messages[0].address,
              amount: "18000000000",
              payload,
            }),
          } as SendTransactionRequest)
      );
    } else {
      removeTxMessage();
    }
  }, [sendTo]);
  return (
    tx && (
      <ScMint>
        <div className="mint">
          <div className="mint-description">Slide to select a character</div>
          <ButtonMint className="mint__button" onClick={handleMint}>
            Buy {tonWeb.utils.fromNano(price)} TON
          </ButtonMint>
        </div>
      </ScMint>
    )
  );
}

async function validateMint(queue: ExportQueue) {
  const params = new URLSearchParams();
  params.append(BLOCKCHAIN_QUERY_PARAM, "TON");
  const isValid = await isValidSkinColor(params, queue.skinColor);

  if (!isValid.ok) {
    throw new NotValidError("Could not mint this color");
  }

  notification.info({
    message: "Please, confirm operation in your wallet",
    duration: 10,
  });
}
