import { ScreenSize } from "../../../models/model";
import styled from "styled-components";

const device = {
  tablet: `max-width: ${ScreenSize.TABLET}px`,
  mobile: `max-width: ${ScreenSize.MOBILE}px`,
};

const defaultTheme = {
  title: "default",
  dark: "#021228",
  text: "#FDFFFD",
  green: "#4DDA36",
};

export const Button = styled.button`
    font-family: "Monserat", sans-serif;
    padding: 15px 60px;
    filter: drop-shadow(10px 10px 30px rgba(17, 227, 76, 0.1));
    border-radius: 14px;
    border: 5px solid ${defaultTheme.green};
    background: transparent;
    transition: all 0.2s ease;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: ${defaultTheme.text};

    &:hover:not(.disabled) {
        background: ${defaultTheme.green};
    }

    &:not(.disabled) {
        cursor: pointer;
    }

    &.disabled {
        opacity: 0.5;
    }

    @media (${device.tablet}) {
        width: 169px;
        border-width: 4px;
        height: 56px;
        font-size: 18px;
        line-height: 29px;
    }

    @media (${device.mobile}) {
        max-width: 169px;
        width: 100%;
    }
`;

export const HeaderButton = styled.button`
  font-family: "Monserat", sans-serif;
  box-sizing: border-box;
  cursor: pointer;
  width: 230px;
  height: 50px;
  filter: drop-shadow(10px 10px 30px rgba(17, 227, 76, 0.1));
  border-radius: 14px;
  border: 5px solid ${defaultTheme.green};
  background: transparent;
  transition: all 0.2s ease;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  color: ${defaultTheme.text};

  &:hover {
    background: ${defaultTheme.green};
  }

  @media (${device.tablet}) {
    width: 220px;
    border-width: 4px;
    height: 56px;
    font-size: 18px;
    line-height: 29px;
  }

  @media (${device.mobile}) {
    font-size: 16px;
    line-height: 30px;
    white-space: nowrap;
    padding: 0 14px;
    height: 50px;
  }
`;

export const Link = styled.a`
    font-family: "Monserat", sans-serif;
    box-sizing: border-box;
    cursor: pointer;
    width: 170px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(10px 10px 30px rgba(17, 227, 76, 0.1));
    border-radius: 14px;
    border: 5px solid ${defaultTheme.green};
    background: transparent;
    transition: all 0.2s ease;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: ${defaultTheme.text};

    &:hover {
        background: ${defaultTheme.green};
        color: ${defaultTheme.text};
    }

    @media (${device.tablet}) {
        width: 169px;
        border-width: 4px;
        height: 56px;
        font-size: 18px;
        line-height: 29px;
    }

    @media (${device.mobile}) {
        font-size: 16px;
        line-height: 30px;
        white-space: nowrap;
        //width: 100%;
        padding: 0 14px;
        height: 50px;
    }
`;
