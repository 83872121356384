import { ScreenSize } from "../../models/model";
import "./style.scss";
import { useTonConnectUI } from "@tonconnect/ui-react";
import React, { useEffect, useRef, useState } from "react";
import { Debugger } from "src/components/AppTitle/Debugger/Debugger";
import styled from "styled-components";

const device = {
  tablet: `max-width: ${ScreenSize.TABLET}px`,
  mobile: `max-width: ${ScreenSize.MOBILE}px`,
};

const ScHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content;
  @media (${device.tablet}) {
  }
  @media (${device.mobile}) {
    justify-content: center;
    img {
      width: 131px;
      height: 42px;
    }
  }
`;

export function AppTitle() {
  const [tonConnectUI] = useTonConnectUI();
  const debuggerRef = useRef<{ open: () => void }>();

  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    if (clicks >= 5) {
      debuggerRef.current!.open();
      setClicks(0);
    }
  }, [clicks]);

  return (
    <>
      <div className="dapp-title" onClick={() => setClicks((x) => x + 1)}>
        <span className="dapp-title__text">TON chain:</span>
        {tonConnectUI.connected && (
          <span className="dapp-title__badge">
            {tonConnectUI.account?.chain}
          </span>
        )}
      </div>
      <ScHeader>
        <img src="/logo.png" alt="logo" />
      </ScHeader>
      <Debugger ref={debuggerRef} />
    </>
  );
}
