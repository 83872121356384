import GoogleLogo from "../../../../assets/modal/google-logo.svg";
import { GoogleAuth } from "./GoogleAuth";

export default function GoogleAuthButton({ children, ...props }: any) {
  return (
    <GoogleAuth {...props}>
      <img src={GoogleLogo} alt="google-logo" />
      <span>{children}</span>
    </GoogleAuth>
  );
}
