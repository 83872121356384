import { request } from "../../../config/variables";
import "./style.scss";
import { useEffect, useState } from "react";
import { Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

interface CustomizationSliderProps {
  imageLinks: string[];
  action: (link: string) => void;
  className: string;
}

export const CustomizationSlider = ({
  imageLinks,
  action,
  className,
}: CustomizationSliderProps) => {
  const [active, setActive] = useState("");

  useEffect(() => {
    setActive("");
  }, [imageLinks]);

  return (
    <Swiper
      className="customization-slider"
      modules={[Scrollbar, Navigation]}
      loop={false}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      speed={500}
      slidesPerView="auto"
      initialSlide={0}
      centeredSlides={false}
      slideToClickedSlide={false}
      preventClicks={false}
      autoHeight={false}
      grabCursor={false}
      spaceBetween={10}
      allowTouchMove={true}
      simulateTouch={true}
      navigation={{
        nextEl: ".customization-slider__button-next",
        prevEl: ".customization-slider__button-prev",
      }}
      scrollbar={{
        el: ".swiper-scrollbar",
        draggable: true,
      }}
    >
      {imageLinks.map((imageLink) => (
        <SwiperSlide
          key={imageLink}
          className={
            active === imageLink
              ? "customization-slider__slide active"
              : "customization-slider__slide"
          }
          onClick={() => {
            setActive(imageLink);
            action(imageLink);
          }}
        >
          <img
            className={className}
            src={request.pathToMin + imageLink}
            color="black"
            alt="head"
          />
        </SwiperSlide>
      ))}
      <div className="customization-slider__button-prev swiper-button-prev"></div>
      <div className="customization-slider__button-next swiper-button-next"></div>
      <div className="swiper-scrollbar"></div>
    </Swiper>
  );
};
