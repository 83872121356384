import { Button } from "../CommonButton/Button";
import styled from "styled-components";

export const MainMenu = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 5px;
  }
`;
