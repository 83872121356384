import styled from 'styled-components';
const defaultTheme = {
  title: 'default',
  white: '#ffffff',
};

export const ScSocial = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;

    a {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-decoration-line: underline;
        color: ${defaultTheme.white};

        &:hover {
            text-decoration: underline;
        }
    }
`;
