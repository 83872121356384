export const NFT_COLLECTION_ADDRESS =
  process.env.REACT_APP_NFT_COLLECTION_ADDRESS ??
  "0:0b447ac2f18d7bb59eccfc1693a3ae42c9968ff0879db34f400d0f1e3cf15836";
export const TON_CENTER_HOST =
  process.env.REACT_APP_TON_CENTER_HOST ??
  "https://testnet.toncenter.com/api/v2/jsonRPC";
export const TON_CENTER_API_KEY =
  process.env.REACT_APP_TON_CENTER_API_KEY ??
  "8c59e6d48c7aa00a54ced7073c5937e45cdf65615b9f6b4e94ac1b44eb33667b";
export const  MINTER_ADDRESS =
  process.env.REACT_APP_MINTER_ADDRESS ??
  "EQCsFQuImGww7qDlcvoLw6xphGxW_ZiHC76CDETa8ktEwHY9";
export const CONTRACT_CREATOR =
  process.env.REACT_APP_CONTRACT_CREATOR ??
  "2317D87e46691ECc6203514A4c43fd806db281ff";

export const BLOCKCHAIN_QUERY_PARAM = "blockchain";

export const request = {
  pathToBig: "/cutting",
  pathToMin: "/cutting",
};
