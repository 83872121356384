import { FootballerPart } from "../../../models/model";
import React, { useEffect, useState } from "react";

interface IChooser {
  footballers: FootballerPart[];
  action: (footballer: FootballerPart) => void;
  color: string;
}

export const Chooser = ({ footballers, action, color }: IChooser) => {
  const [active, setActive] = useState<FootballerPart>();

  const updateActive = (footballer: FootballerPart) => {
    setActive(footballer);
    action(footballer);
  };

  useEffect(() => {
    const firstBag = footballers.find(
      (footballer) => footballer.playerType === "bag"
    );
    if (firstBag) {
      updateActive(firstBag);
    }
  }, [footballers]);

  return (
    <div
      className={
        footballers.length === 0
          ? "custom-choose custom-choose-none"
          : "custom-choose"
      }
    >
      {footballers?.map((footballer, index) => (
        <div
          key={index}
          onClick={() => updateActive(footballer)}
          className={
            active?.number === footballer.number &&
            color === footballer.skinColor
              ? "custom-choose__item custom-choose__item-active"
              : "custom-choose__item"
          }
        >
          <div className="custom-choose__image">
            <img src={footballer.image} alt="character" />
          </div>
        </div>
      ))}
    </div>
  );
};
