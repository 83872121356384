import styled from "styled-components";

export const Button = styled.button`
    color: #FDFFFD;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    border: 3px solid #4DDA36;
    box-shadow: 10px 10px 30px 0 #11E34C1A;
    padding: 14px;
    border-radius: 14px;
    width: 100%;
    background: none;
    cursor: pointer;
`;
