import { getSkinsAndCount } from "../../config/requests";
import { SkinsAndCount } from "../../models/model";
import { initialPlayers } from "../../state/mock";
import { TxForm } from "../TxForm/TxForm";
import { Customization } from "../customization";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { useCallback, useEffect, useState } from "react";
import { Card } from "src/components/slider/card";
import { ScSlider } from "src/components/slider/styled";
import { EffectCoverflow, Swiper as SwiperType } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { useAccount } from "wagmi";

export const Slider = ({ username, updateProfile }: any) => {
  const [index, setIndex] = useState(0);
  const [color, setColor] = useState("sand");
  const [skinsCount, setSkinsCount] = useState<SkinsAndCount[]>([]);
  const [swiper, setSwiper] = useState<SwiperType>();
  const { isConnected } = useAccount();
  const [tonConnectUI] = useTonConnectUI();

  const defineOpacity = useCallback(
    (i: number) => {
      const len = initialPlayers.length;
      const opacity = initialPlayers.map(() => 0);
      opacity[index] = 1;
      opacity[(index + 1) % len] = 0.8;
      opacity[(index + len - 1) % len] = 0.8;
      opacity[(index + 2) % len] = 0.6;
      opacity[(index + len - 2) % len] = 0.6;
      opacity[(index + 3) % len] = 0.4;
      opacity[(index + len - 3) % len] = 0.4;
      return opacity[i];
    },
    [index]
  );

  useEffect(() => {
    const fetchAllSkinsCount = async () => {
      const params = new URLSearchParams();
      console.log(isConnected);
      if (tonConnectUI.connected) {
        params.append("blockchain", "TON");
      } else if (isConnected) {
        params.append("blockchain", "ETH");
      }
      setSkinsCount(await getSkinsAndCount(params));
    };
    if (isConnected || tonConnectUI.connected) {
      fetchAllSkinsCount();
    }
  }, [isConnected, tonConnectUI.connected]);

  const findIndexByColor = (color?: string) => {
    return swiper?.slides
      .filter((sl) => sl.children[0].getAttribute("color") === color)
      .map(
        (sl) => sl.attributes.getNamedItem("data-swiper-slide-index")?.value
      )[0];
  };

  const handleChangeColor = (color?: string) => {
    const index = findIndexByColor(color);
    if (index) swiper?.slideToLoop(+index, 1000);
  };

  return (
    <>
      <ScSlider>
        <Swiper
          onSwiper={(swiper) => setSwiper(swiper)}
          modules={[EffectCoverflow]}
          effect="coverflow"
          slidesPerView="auto"
          spaceBetween={20}
          centeredSlides
          loop
          slideToClickedSlide={true}
          coverflowEffect={{
            rotate: 0,
            stretch: 1,
            depth: 50,
            scale: 0.95,
            modifier: 1,
            slideShadows: false,
          }}
          onRealIndexChange={(swiper) => {
            setColor((c) => {
              const color =
                swiper.slides[swiper.realIndex].children[0].getAttribute(
                  "color"
                );
              return color !== null ? color : c;
            });
            setIndex(swiper.realIndex);
          }}
        >
          {initialPlayers.map((player, i) => (
            <SwiperSlide
              key={player.color}
              style={{
                opacity: defineOpacity(i),
              }}
            >
              <Card player={player} skinsCount={skinsCount} />
            </SwiperSlide>
          ))}
        </Swiper>
      </ScSlider>
      <TxForm color={color} username={username} />
      <Customization
        theme={color}
        changeColor={handleChangeColor}
        username={username}
        updateProfile={updateProfile}
      />
    </>
  );
};
