export class Footballer implements FootballerPart {
  bootColor?: string;
  headType?: string;
  shortColor?: string;
  skinColor?: string;
  tShirtColor?: string;

  constructor() {}
}

export type Metadata = {
  image: string;
  name: string;
  description: string;
  attributes: [];
  index?: string;
};

export interface FootballerPart {
  headType?: string;
  bootColor?: string;
  shortColor?: string;
  skinColor?: string;
  tShirtColor?: string;
  image?: string;
  playerType?: string;
  number?: string;
  email?: string;
}

export interface FootballerResponse {
  nftIndex: number;
  email: string;
  skinColor: string;
  image: string;
  footballerPartId: number;
  footballerPart: {
    headType: string;
    tShirtColor: string;
    shortColor: string;
    bootColor: string;
    skinColor: string;
  };
}

export interface ImageType {
  src?: string;
  className?: string;
  alt?: string;
}

export interface SkinsAndCount {
  skinColor: string;
  skinLeft: string;
}

export type Player = {
  image: string;
  color: string;
  name: string;
};

export enum ScreenSize {
  TABLET = 900,
  MOBILE = 500,
}

export class ExportQueue {
  nftIndex?: number;
  footballerPart?: Footballer;
  skinColor?: string;
  addressId?: string;
  operation?: "mint" | "updateClothes";
  email?: string;
  blockchain?: "TON" | "ETH";
}

export interface ILogin {
  username: string;
  password: string;
}

export interface INftModifyRequest {
  nftIndex: number;
  headType: string;
  bootColor: string;
  shortColor: string;
  tShirtColor: string;
}

export interface Web2User {
  provider: string;
  username: string;
  id: number;
}

export type ImageLinks = {
  heads: string[];
  tShirts: string[];
  shorts: string[];
  boots: string[];
};
