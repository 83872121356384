export const WhitelistMintABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address[]", name: "users", type: "address[]" },
      {
        internalType: "uint256[]",
        name: "prices",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "maxMintQuantities",
        type: "uint256[]",
      },
    ],
    name: "addArrToWhitelist",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "user", type: "address" },
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
      { internalType: "uint256", name: "qty", type: "uint256" },
    ],
    name: "addToWhitelist",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract FootballCollection",
        name: "newFootballCollection",
        type: "address",
      },
    ],
    name: "changeFootballCollection",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "newMintPrice", type: "uint256" },
    ],
    name: "changeMintPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "footballCollection",
    outputs: [
      {
        internalType: "contract FootballCollection",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract FootballCollection",
        name: "setFootballCollection",
        type: "address",
      },
      { internalType: "uint256", name: "setMintPrice", type: "uint256" },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "mintPrice",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "user", type: "address" },
      {
        internalType: "uint256",
        name: "qty",
        type: "uint256",
      },
    ],
    name: "priceFor",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "user", type: "address" }],
    name: "removeFromWhitelist",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "whitelist",
    outputs: [
      { internalType: "uint256", name: "mintPriceForUser", type: "uint256" },
      {
        internalType: "uint256",
        name: "availableForMint",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

// export const whiteListAddress = "0x2fE1961c9359af0e41aC1f1B7cC2C56953111CDe";
const isProduction = process.env.REACT_APP_IS_PRODUCTION;
export const testnetAddress = "0x79511ca6A2985A8DF425F5522A9D33235A3d2EaA";
export const productionAddress = "0x3e14b4b787538b94ada430eb79cd5e6a35afa4e3";
export const whiteListAddress =
  isProduction === "true" ? productionAddress : testnetAddress;
