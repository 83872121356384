import { login, registration } from "../../../config/requests";
import { ILogin } from "../../../models/model";
import { Helper } from "../AdditionalComponents";
import CommonButton from "../Buttons/CommonButton/CommonButton";
import { Input } from "../Inputs/Input";
import { Form } from "./Form";
import { notification } from "antd";
import React, { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

export default function LoginForm({ setClose }: any) {
  const [hasPassword, setHasPassword] = useState<boolean>(false);
  const { register, handleSubmit, reset } = useForm<ILogin>({});

  const signUp = useCallback(async (data: ILogin) => {
    return await registration(data);
  }, []);

  const signIn = useCallback(async (data: ILogin) => {
    return await login(data);
  }, []);

  const onSubmit: SubmitHandler<ILogin> = async (data) => {
    if (hasPassword) {
      const response = await signIn(data);
      if (response.ok) {
        setClose();
      } else {
        notification.error({
          message: "Not authorized",
          description: (await response.json()).detail ?? "",
          duration: 10,
        });
      }
    } else {
      const response = await signUp(data);
      if (!response.ok) {
        notification.error({
          message: "Registration unavailable",
          description: (await response.json()).detail ?? "",
          duration: 10,
        });
      }
      setHasPassword(true);
    }
  };

  return (
    <Form className="login-form" onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register("username", {
          required: "Email is required",
        })}
        placeholder="E-mail"
        autoComplete="off"
      />
      {hasPassword && (
        <Input
          {...register("password")}
          placeholder="Password"
          autoComplete="off"
          type="password"
        />
      )}
      <CommonButton>{hasPassword ? "Enter" : "Send Password"}</CommonButton>
      <Helper onClick={() => setHasPassword((prev) => !prev)}>
        {hasPassword
          ? "Don’t have the password"
          : "I already have the password"}
      </Helper>
    </Form>
  );
}
