import { Image } from "../../templates/templates";
import React from "react";

export default function CustomizedCharacter({
  head,
  rightShoulder,
  leftShoulder,
  tShirt,
  rightHand,
  leftHand,
  pants,
  legs,
  boots,
}: any) {
  return (
    <div className="custom__human custom-human">
      <Image src={head} className="custom-human__head" />
      <Image src={rightShoulder} className="custom-human__right-shoulder" />
      <Image src={leftShoulder} className="custom-human__left-shoulder" />
      <Image src={tShirt} className="custom-human__body" />
      <Image src={rightHand} className="custom-human__right-hand" />
      <Image src={leftHand} className="custom-human__left-hand" />
      <Image src={pants} className="custom-human__underpants" />
      <Image src={legs} className="custom-human__legs" />
      <Image src={boots} className="custom-human__boots" />
    </div>
  );
}
