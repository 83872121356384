import styled from "styled-components";

export const Input = styled.input`
    border: 3px solid #FDFFFD;
    border-radius: 14px;
    box-shadow: 10px 10px 30px 0 #11E34C1A;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
    width: 100%;
    height: 100%;
    background: none;
    color: #FDFFFD;
    padding: 14px;
    outline: none;
`