import { ScreenSize } from "../../../models/model";
import styled from "styled-components";

const device = {
  tablet: `max-width: ${ScreenSize.TABLET}px`,
  mobile: `max-width: ${ScreenSize.MOBILE}px`,
};

const defaultTheme = {
  title: "default",
  dark: "#021228",
  text: "#FDFFFD",
  green: "#4DDA36",
};

export const ButtonMint = styled.button`
  font-family: "Monserat", sans-serif;
  box-sizing: border-box;
  cursor: pointer;
  padding: 20px 60px;
  filter: drop-shadow(10px 10px 30px rgba(17, 227, 76, 0.1));
  border-radius: 14px;
  border: 5px solid ${defaultTheme.green};
  background: transparent;
  transition: all 0.2s ease;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${defaultTheme.text};

  &:hover {
    background: ${defaultTheme.green};
  }

  @media (${device.tablet}) {
    padding: 10px 30px;
  }

  @media (${device.mobile}) {
    font-size: 18px;
    line-height: 22px;
    padding: 10px;
    width: 100%;
    &.mint__button {
      padding: 15px;
    }
  }
`;

