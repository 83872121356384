import { AppTitle } from "../AppTitle/AppTitle";
import EthButton from "../Modal/Buttons/AuthButtons/EthButtons/EthButton";
import MainAuthButton from "../Modal/Buttons/AuthButtons/MainAuthButton";
import TonDisconnectButton from "../Modal/Buttons/AuthButtons/TonButtons/TonDisconnectButton";
import SignOutButton from "../Modal/Buttons/AuthButtons/Web2Buttons/SignOutButton";
import MainMenu from "../Modal/Menu/MainMenu";
import { HeaderContainer } from "./HeaderContainer";
import { useTonConnectUI } from "@tonconnect/ui-react";
import React, { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";

export default function Header({ username, updateProfile }: any) {
  const [tonConnectUI] = useTonConnectUI();
  const { isConnected } = useAccount();
  const [open, setOpen] = useState(false);
  const [isTonConnected, setIsTonConnected] = useState(tonConnectUI.connected);

  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const WalletInfo = useCallback(() => {
    if (isTonConnected) {
      return <TonDisconnectButton updateProfile={updateProfile} />;
    } else if (username) {
      return (
        <SignOutButton username={username} updateProfile={updateProfile} />
      );
    } else if (isConnected) {
      return <EthButton mainModalClose={() => console.log("close")} />;
    }
    return <MainAuthButton onClick={toggleOpen} />;
  }, [isTonConnected, isConnected, username, updateProfile]);

  useEffect(() => {
    const unsubscribe = tonConnectUI.onStatusChange((wallet) => {
      setIsTonConnected(tonConnectUI.connected);
    });

    return () => unsubscribe();
  }, [tonConnectUI]);

  return (
    <HeaderContainer>
      <AppTitle />
      <div className="auth-buttons">
        <MainMenu
          isOpen={open}
          onClose={toggleOpen}
          username={username}
          updateProfile={updateProfile}
        />
        <WalletInfo />
      </div>
    </HeaderContainer>
  );
}
