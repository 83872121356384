import styled from "styled-components";

export const GoogleAuth = styled.a`
    border: 3px solid #4DDA36;
    background: none;
    box-shadow: 10px 10px 30px 0 #11E34C1A;
    padding: 14px;
    border-radius: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    span {
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0;
        color: #FDFFFD;
    }
    img {
        width: 22px;
        height: 22px;
    }
`