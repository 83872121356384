import EthButton from "../Buttons/AuthButtons/EthButtons/EthButton";
import TonConnectButton from "../Buttons/AuthButtons/TonButtons/TonConnectButton";
import SignInButton from "../Buttons/AuthButtons/Web2Buttons/SignInButton";
import "./style.scss";
import { Modal } from "antd";
import React from "react";

interface IMainMenu {
  isOpen: boolean;
  onClose: () => void;
  username: string;
  updateProfile: () => void;
}

export default function MainMenu({
  isOpen,
  onClose,
  username,
  updateProfile,
}: IMainMenu) {
  return (
    <Modal
      title={null}
      open={isOpen}
      onOk={() => console.log("ok")}
      onCancel={onClose}
      className={"main-menu"}
      wrapClassName={"main-menu-wrapper"}
      width={414}
      cancelButtonProps={{ className: "sign-button sign-button_cancel" }}
      okButtonProps={{ className: "sign-button sign-button_ok" }}
      zIndex={999}
      bodyStyle={{ background: "#082148", borderRadius: "32px" }}
      style={{ background: "#082148", borderRadius: "32px" }}
      footer={null}
    >
      <SignInButton
        username={username}
        updateProfile={updateProfile}
        mainModalClose={onClose}
      />
      <TonConnectButton mainModalClose={onClose} />
      <EthButton mainModalClose={onClose} />
    </Modal>
  );
}
