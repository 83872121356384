import { ScreenSize } from "../../models/model";
import styled from "styled-components";

const device = {
  tablet: `max-width: ${ScreenSize.TABLET}px`,
  mobile: `max-width: ${ScreenSize.MOBILE}px`,
};

export const ScMint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  padding: 0 20px;
  justify-content: center;
  @media (${device.mobile}) {
    width: 100%;
  }

  .mint {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;

    &-description {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: rgb(253, 255, 253);
    }

    @media (${device.mobile}) {
      width: 100%;
      justify-content: center;
    }
  }

  @media (${device.tablet}) {
    margin-top: 40px;
    flex-direction: column;
    gap: 30px;
    padding: 0;
  }
`;
