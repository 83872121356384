import TonIcon from "../../../../../assets/modal/ton.svg";
import { getNonce, tonkeeperLogin } from "../../../../../config/requests";
import MainMenuButton from "../../MainMenuButton/MainMenuButton";
import { useTonConnectModal, useTonConnectUI } from "@tonconnect/ui-react";
import React, { useCallback, useEffect } from "react";

interface ITonConnectButton {
  mainModalClose: () => void;
}

export default function TonConnectButton({
  mainModalClose,
}: ITonConnectButton) {
  const { open, state } = useTonConnectModal();
  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    if (tonConnectUI.connected) {
      mainModalClose();
    }
  }, [tonConnectUI.connected]);

  const handleOpen = useCallback(async () => {
    const nonce = (await (await getNonce()).json()).nonce;

    tonConnectUI.setConnectRequestParameters({
      state: "ready",
      value: { tonProof: nonce },
    });
    open();
  }, [open]);

  useEffect(() => {
    tonConnectUI.onStatusChange(async (wallet) => {
      if (!wallet) {
        localStorage.removeItem("access_token");
        return;
      }

      if (
        wallet.connectItems?.tonProof &&
        "proof" in wallet.connectItems.tonProof
      ) {
        const response = await tonkeeperLogin(wallet);
        const responseJson = await response.json();
        const accessToken = responseJson.access_token;
        if (response.status != 201 || !accessToken) {
          await tonConnectUI.disconnect();
          localStorage.removeItem("access_token");
          return;
        }
        localStorage.setItem("access_token", accessToken);
      }
    });
  }, [tonConnectUI]);

  return (
    <>
      <MainMenuButton src={TonIcon} title="TON" action={handleOpen} />
    </>
  );
}
