import { Base64 } from "@tonconnect/protocol";
import { Address, beginCell } from "ton";

export function generatePayload(sendTo: string): string {
  const op = 0x5fcc3d14; // transfer
  const quiryId = 0;
  const messageBody = beginCell()
    .storeUint(op, 32)
    .storeUint(quiryId, 64)
    .storeAddress(Address.parse(sendTo))
    .storeUint(0, 2)
    .storeInt(0, 1)
    .storeCoins(0)
    .endCell();

  return Base64.encode(messageBody.toBoc());
}
