import { Image } from "../../../templates/templates";
import { MainMenu } from "./MainMenu";
import React from "react";

interface IMainMenuButton {
  src: string;
  title?: string;
  action: () => void;
}

export default function MainMenuButton({ src, title, action }: IMainMenuButton) {
  return (
    <MainMenu onClick={action}>
      <Image src={src} />
      <span>{title}</span>
    </MainMenu>
  );
}
