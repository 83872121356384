import Pants from "../../../assets/Pants.svg";
import Head from "../../../assets/Smiley.svg";
import Boots from "../../../assets/Sneaker.svg";
import TShirt from "../../../assets/TShirt.svg";
import { Image } from "../../templates/templates";
import React from "react";

const parts = [
  {
    image: Head,
    text: "heads",
  },
  {
    image: TShirt,
    text: "tShirts",
  },
  {
    image: Pants,
    text: "shorts",
  },
  {
    image: Boots,
    text: "boots",
  },
];

export const Parts = ({ action, activePart }: any) => {
  return (
    <div className="custom__parts">
      {parts.map((part, index) => (
        <div
          key={index}
          onClick={() => action(part.text)}
          className={
            activePart === part.text ? "custom__part active" : "custom__part"
          }
        >
          <Image src={part.image} alt={part.text} />
        </div>
      ))}
    </div>
  );
};
