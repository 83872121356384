import Eth from "../../../../../assets/modal/Ethereum Foundation svg.svg";
import MainMenuButton from "../../MainMenuButton/MainMenuButton";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import React, { useEffect } from "react";
import { useAccount } from "wagmi";

interface IEthButton {
  mainModalClose: () => void;
}

export default function EthButton({ mainModalClose }: IEthButton) {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();

  useEffect(() => {
    if (isConnected) mainModalClose();
  }, [isConnected]);

  return (
    <MainMenuButton
      src={Eth}
      title={
        isConnected
          ? address?.slice(0, 4) + "..." + address?.slice(-3)
          : "Ethereum"
      }
      action={() => {
        if (isConnected) {
          open({ view: "Account" });
        } else {
          open({ view: "Connect" });
        }
      }}
    />
  );
}
