import styled from "styled-components";
import {ScreenSize} from "../../models/model";

const device = {
  tablet: `max-width: ${ScreenSize.TABLET}px`,
  mobile: `max-width: ${ScreenSize.MOBILE}px`,
};

export const ScSlider = styled.div`
  width: 100%;
  margin-top: 54px;
  margin-bottom: 80px;
  box-sizing: border-box;
  position: relative;
  height: 322.74px;
  .swiper {
    padding-bottom: 47px;
    width: 1426px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .swiper-slide {
    width: fit-content !important;
  }
  @media (${device.tablet}) {
    margin-bottom: 60px;
    .swiper {
      transform: translateX(-50%) scale(0.9);
    }
  }
  @media (${device.mobile}) {
    margin-top: 0;
    height: 260px;
    margin-bottom: 40px;
    .swiper {
      transform: translateX(-50%) scale(0.7);
    }
  }
`;
