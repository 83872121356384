import App from "./App";
import { Web3ModalProvider } from "./components/providers/Web3ModalProvider";
import "./index.scss";
import "./patch-local-storage-for-github-pages";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <TonConnectUIProvider
    manifestUrl="https://mint.shaolin.soccer/tonconnect-manifest.json"
    uiPreferences={{ theme: THEME.DARK }}
  >
    <Web3ModalProvider>
      <RecoilRoot>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </RecoilRoot>
    </Web3ModalProvider>
  </TonConnectUIProvider>
);
