import styled from "styled-components";

export const Separator = styled.div`
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: #FDFFFD;
    text-align: center;
`

export const Helper = styled.div`
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #4DDA36;
    text-decoration: underline;
    cursor: pointer;
`