import { disconnect } from "../../../../../config/requests";
import { HeaderButton } from "../../../../ui/Button";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import React, { useCallback } from "react";


interface ISignOutButton {
  username: string;
  updateProfile: () => void;
}

export default function SignOutButton({
  username,
  updateProfile,
}: ISignOutButton) {
  const handleLogout = useCallback(async () => {
    await disconnect();
    updateProfile();
  }, []);

  const menu = {
    onClick: handleLogout,
    items: [
      {
        label: "Disconnect",
        key: "1",
      },
    ],
  };

  return (
    <Dropdown className="header-dropdown" menu={menu}>
      <HeaderButton>
        <Space color="#FDFFFD">
          {username}
          <DownOutlined />
        </Space>
      </HeaderButton>
    </Dropdown>
  );
}
