import styled from "styled-components";

export const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    max-width: 1450px;
    padding: 0 15px;
    top: 40px;
    width: 100%;
    transform: translateX(-50%);
    @media (max-width: 1088px) {
        position: static;
        left: auto;
        top: auto;
        transform: translateX(0);
        margin-bottom: 40px;
    }
    @media (max-width: 540px) {
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        margin-bottom: 15px;
        width: 100%;
    }
`;
