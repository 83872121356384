import { Player } from "../models/model";

export const headImageLinks = [
  "/heads/head_ivory_hBlack_nRoundBit_mLittle.png",
  "/heads/head_ivory_hClay_nRoundBit_mLittle.png",
  "/heads/head_ivory_hGray_nRoundBit_mLittle.png",
  "/heads/head_porcelain_hGray_nBig.png",
  "/heads/head_roseBeige_hOrange_nAngleRound.png",
];

export const tShirtImageLinks = [
  "/clothes/tShirts/tShirt_black.png",
  "/clothes/tShirts/tShirt_cyan.png",
  "/clothes/tShirts/tShirt_gray.png",
];

export const pantsImageLinks = [
  "/clothes/shorts/shorts_blue.png",
  "/clothes/shorts/shorts_blue_stripeRed_num10.png",
  "/clothes/shorts/shorts_gray.png",
];

export const bootsImageLinks = [
  "/clothes/boots/boots_black.png",
  "/clothes/boots/boots_salad.png",
  "/clothes/boots/boots_red_stripeWhite3.png",
];

export type Skin = {
  color: string;
  attrs: {
    head: string;
    body: string;
    leftShoulder: string;
    leftHand: string;
    rightShoulder: string;
    rightHand: string;
    legs: string;
    pants: string;
  };
};

// export const skin_bags: Skin[] = [
//   {
//     color: "brown",
//     attrs: {
//       head: "/customization/heads/head_brown_bag.png",
//       body: "/customization/colors/body_brown.png",
//       leftShoulder: "/customization/colors/leftHand_brown.png",
//       leftHand: "/customization/colors/leftFist_brown.png",
//       rightShoulder: "/customization/colors/rightHand_brown.png",
//       rightHand: "/customization/colors/rightFist_brown.png",
//       legs: "/customization/colors/legs_brown.png",
//       pants: "/customization/shorts/underpands-1.png",
//     },
//   },
//   {
//     color: "bronze",
//     attrs: {
//       head: "/customization/heads/head_bronze_bag.png",
//       body: "/customization/colors/body_bronze.png",
//       leftShoulder: "/customization/colors/leftHand_bronze.png",
//       leftHand: "/customization/colors/leftFist_bronze.png",
//       rightShoulder: "/customization/colors/rightHand_bronze.png",
//       rightHand: "/customization/colors/rightFist_bronze.png",
//       legs: "/customization/colors/legs_bronze.png",
//       pants: "/customization/shorts/underpands-1.png",
//     },
//   },
//   {
//     color: "chestnut",
//     attrs: {
//       head: "/customization/heads/head_chestnut_bag.png",
//       body: "/customization/colors/body_chestnut.png",
//       leftShoulder: "/customization/colors/leftHand_chestnut.png",
//       leftHand: "/customization/colors/leftFist_chestnut.png",
//       rightShoulder: "/customization/colors/rightHand_chestnut.png",
//       rightHand: "/customization/colors/rightFist_chestnut.png",
//       legs: "/customization/colors/legs_chestnut.png",
//       pants: "/customization/shorts/underpands-1.png",
//     },
//   },
//   {
//     color: "ivory",
//     attrs: {
//       head: "/customization/heads/head_ivory_bag.png",
//       body: "/customization/colors/body_ivory.png",
//       leftShoulder: "/customization/colors/leftHand_ivory.png",
//       leftHand: "/customization/colors/leftFist_ivory.png",
//       rightShoulder: "/customization/colors/rightHand_ivory.png",
//       rightHand: "/customization/colors/rightFist_ivory.png",
//       legs: "/customization/colors/legs_ivory.png",
//       pants: "/customization/shorts/underpands-1.png",
//     },
//   },
//   {
//     color: "roseBeige",
//     attrs: {
//       head: "/customization/heads/head_roseBeige_bag.png",
//       body: "/customization/colors/body_roseBeige.png",
//       leftShoulder: "/customization/colors/leftHand_roseBeige.png",
//       leftHand: "/customization/colors/leftFist_roseBeige.png",
//       rightShoulder: "/customization/colors/rightHand_roseBeige.png",
//       rightHand: "/customization/colors/rightFist_roseBeige.png",
//       legs: "/customization/colors/legs_roseBeige.png",
//       pants: "/customization/shorts/underpands-1.png",
//     },
//   },
//   {
//     color: "sienna",
//     attrs: {
//       head: "/customization/heads/head_sienna_bag.png",
//       body: "/customization/colors/body_sienna.png",
//       leftShoulder: "/customization/colors/leftHand_sienna.png",
//       leftHand: "/customization/colors/leftFist_sienna.png",
//       rightShoulder: "/customization/colors/rightHand_sienna.png",
//       rightHand: "/customization/colors/rightFist_sienna.png",
//       legs: "/customization/colors/legs_sienna.png",
//       pants: "/customization/shorts/underpands-1.png",
//     },
//   },
//   {
//     color: "sand",
//     attrs: {
//       head: "/customization/heads/head_sand_bag.png",
//       body: "/customization/colors/body_sand.png",
//       leftShoulder: "/customization/colors/leftHand_sand.png",
//       leftHand: "/customization/colors/leftFist_sand.png",
//       rightShoulder: "/customization/colors/rightHand_sand.png",
//       rightHand: "/customization/colors/rightFist_sand.png",
//       legs: "/customization/colors/legs_sand.png",
//       pants: "/customization/shorts/undrpands-1.png",
//     },
//   },
//   {
//     color: "porcelain",
//     attrs: {
//       head: "/customization/heads/head_porcelain_bag.png",
//       body: "/customization/colors/body_porcelain.png",
//       leftShoulder: "/customization/colors/leftHand_porcelain.png",
//       leftHand: "/customization/colors/leftFist_porcelain.png",
//       rightShoulder: "/customization/colors/rightHand_porcelain.png",
//       rightHand: "/customization/colors/rightFist_porcelain.png",
//       legs: "/customization/colors/legs_porcelain.png",
//       pants: "/customization/shorts/underpands-1.png",
//     },
//   },
//   {
//     color: "honey",
//     attrs: {
//       head: "/customization/heads/head_honey_bag.png",
//       body: "/customization/colors/body_honey.png",
//       leftShoulder: "/customization/colors/leftHand_honey.png",
//       leftHand: "/customization/colors/leftFist_honey.png",
//       rightShoulder: "/customization/colors/rightHand_honey.png",
//       rightHand: "/customization/colors/rightFist_honey.png",
//       legs: "/customization/colors/legs_honey.png",
//       pants: "/customization/shorts/underpands-1.png",
//     },
//   },
// ];

export const skins = {
  sand: {
    head: "/customization/heads/head_sand_bag.png",
    body: "/customization/colors/body_sand.png",
    leftShoulder: "/customization/colors/leftHand_sand.png",
    leftHand: "/customization/colors/leftFist_sand.png",
    rightShoulder: "/customization/colors/rightHand_sand.png",
    rightHand: "/customization/colors/rightFist_sand.png",
    legs: "/customization/colors/legs_sand.png",
    pants: "/customization/shorts/underpands-1.png",
  },
  brown: {
    head: "/customization/heads/head_brown_bag.png",
    body: "/customization/colors/body_brown.png",
    leftShoulder: "/customization/colors/leftHand_brown.png",
    leftHand: "/customization/colors/leftFist_brown.png",
    rightShoulder: "/customization/colors/rightHand_brown.png",
    rightHand: "/customization/colors/rightFist_brown.png",
    legs: "/customization/colors/legs_brown.png",
    pants: "/customization/shorts/underpands-1.png",
  },
  bronze: {
    head: "/customization/heads/head_bronze_bag.png",
    body: "/customization/colors/body_bronze.png",
    leftShoulder: "/customization/colors/leftHand_bronze.png",
    leftHand: "/customization/colors/leftFist_bronze.png",
    rightShoulder: "/customization/colors/rightHand_bronze.png",
    rightHand: "/customization/colors/rightFist_bronze.png",
    legs: "/customization/colors/legs_bronze.png",
    pants: "/customization/shorts/underpands-1.png",
  },
  chestnut: {
    head: "/customization/heads/head_chestnut_bag.png",
    body: "/customization/colors/body_chestnut.png",
    leftShoulder: "/customization/colors/leftHand_chestnut.png",
    leftHand: "/customization/colors/leftFist_chestnut.png",
    rightShoulder: "/customization/colors/rightHand_chestnut.png",
    rightHand: "/customization/colors/rightFist_chestnut.png",
    legs: "/customization/colors/legs_chestnut.png",
    pants: "/customization/shorts/underpands-1.png",
  },
  ivory: {
    head: "/customization/heads/head_ivory_bag.png",
    body: "/customization/colors/body_ivory.png",
    leftShoulder: "/customization/colors/leftHand_ivory.png",
    leftHand: "/customization/colors/leftFist_ivory.png",
    rightShoulder: "/customization/colors/rightHand_ivory.png",
    rightHand: "/customization/colors/rightFist_ivory.png",
    legs: "/customization/colors/legs_ivory.png",
    pants: "/customization/shorts/underpands-1.png",
  },
  roseBeige: {
    head: "/customization/heads/head_roseBeige_bag.png",
    body: "/customization/colors/body_roseBeige.png",
    leftShoulder: "/customization/colors/leftHand_roseBeige.png",
    leftHand: "/customization/colors/leftFist_roseBeige.png",
    rightShoulder: "/customization/colors/rightHand_roseBeige.png",
    rightHand: "/customization/colors/rightFist_roseBeige.png",
    legs: "/customization/colors/legs_roseBeige.png",
    pants: "/customization/shorts/underpands-1.png",
  },
  sienna: {
    head: "/customization/heads/head_sienna_bag.png",
    body: "/customization/colors/body_sienna.png",
    leftShoulder: "/customization/colors/leftHand_sienna.png",
    leftHand: "/customization/colors/leftFist_sienna.png",
    rightShoulder: "/customization/colors/rightHand_sienna.png",
    rightHand: "/customization/colors/rightFist_sienna.png",
    legs: "/customization/colors/legs_sienna.png",
    pants: "/customization/shorts/underpands-1.png",
  },
  porcelain: {
    head: "/customization/heads/head_porcelain_bag.png",
    body: "/customization/colors/body_porcelain.png",
    leftShoulder: "/customization/colors/leftHand_porcelain.png",
    leftHand: "/customization/colors/leftFist_porcelain.png",
    rightShoulder: "/customization/colors/rightHand_porcelain.png",
    rightHand: "/customization/colors/rightFist_porcelain.png",
    legs: "/customization/colors/legs_porcelain.png",
    pants: "/customization/shorts/underpands-1.png",
  },

  honey: {
    head: "/customization/heads/head_honey_bag.png",
    body: "/customization/colors/body_honey.png",
    leftShoulder: "/customization/colors/leftHand_honey.png",
    leftHand: "/customization/colors/leftFist_honey.png",
    rightShoulder: "/customization/colors/rightHand_honey.png",
    rightHand: "/customization/colors/rightFist_honey.png",
    legs: "/customization/colors/legs_honey.png",
    pants: "/customization/shorts/underpands-1.png",
  },
};

export const initialPlayers: Player[] = [
  {
    image: "/players/sand_bag.png",
    color: "sand",
    name: "Sand",
  },
  {
    image: "/players/brown_bag.png",
    color: "brown",
    name: "Brown",
  },
  {
    image: "/players/bronze_bag.png",
    color: "bronze",
    name: "Bronze",
  },
  {
    image: "/players/chestnut_bag.png",
    color: "chestnut",
    name: "Chestnut",
  },
  { image: "/players/ivory_bag.png", color: "ivory", name: "Ivory" },
  {
    image: "/players/roseBeige_bag.png",
    color: "roseBeige",
    name: "Rose Beige",
  },
  {
    image: "/players/sienna_bag.png",
    color: "sienna",
    name: "Sienna",
  },
  {
    image: "/players/porcelain_bag.png",
    color: "porcelain",
    name: "Porcelain",
  },
  {
    image: "/players/honey_bag.png",
    color: "honey",
    name: "Honey",
  },
];
