import { generateLink } from "../../config/requests";
import { Link } from "../ui/Button";
import { useEffect, useState } from "react";

interface IWeb2BuyButton {
  username: string;
  color: string;
}

export default function Web2BuyButton({ username, color }: IWeb2BuyButton) {
  const [link, setLink] = useState("");

  useEffect(() => {
    if (username) {
      const getLink = async () => {
        const response = await generateLink(color);
        setLink(response.link);
      };
      getLink();
    }
  }, [username, color]);

  return (
    <Link href={link} target="_blank">
      Buy
    </Link>
  );
}
