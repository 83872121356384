import styled from "styled-components";
import {ScreenSize} from "../../models/model";

const device = {
  tablet: `max-width: ${ScreenSize.TABLET}px`,
  mobile: `max-width: ${ScreenSize.MOBILE}px`,
};

export const ScContacts = styled.div`
  display: flex;
  align-items: center;
  margin-top: 80px;
  padding: 52px 0;
  font-family: "Montserrat", sans-serif;
  gap: 100px;
  justify-content: center;
  flex-wrap: wrap;
  background: #010C1D;
  & > a {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
  }
  & > p {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #FDFFFD;
    opacity: 0.5;
    margin: 0 -60px 0 0;
  }
  .socials {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  @media (${device.tablet}) {
    margin-top: 40px;
  }
  @media (${device.mobile}) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    & > a {
      font-style: normal;
      font-weight: 500;
      font-size: 16px !important;
      line-height: 20px !important;
    }
    & > p {
      padding: 0;
      margin: 0;
    }
  }
`;
