import shadow from "../../../assets/shadow.svg";
import { Player, SkinsAndCount } from "../../../models/model";
import "./pagination.min.css";
import { ScCard } from "./styled";

interface CardProps {
  player: Player;
  skinsCount: SkinsAndCount[];
}

export const Card = ({ player, skinsCount }: CardProps) => {
  const skinLeft = skinsCount.find(
    (skinsCount) => skinsCount.skinColor === player.color
  );

  return (
    <ScCard color={player.color}>
      <p className="card__title" style={{ textAlign: "center" }}></p>
      <span className="card__type">{player.name}</span>
      <img className="card__footballer" src={player.image} alt="img" />
      <img className="card__shadow" src={shadow} alt="shadow" />
      <p className="card__next">
        {skinLeft ? "Left " + skinLeft.skinLeft : ""}
      </p>
    </ScCard>
  );
};
