import CurrencyCircleDollar from "../../assets/CurrencyCircleDollar.svg";
import GameController from "../../assets/GameController.svg";
import { ScreenSize } from "../../models/model";
import React from "react";
import styled from "styled-components";

export default function IntroDescription() {
  return (
    <ScDescription>
      <h1>
        <span>Shaolin Soccer Mint</span>
      </h1>
      <p>
        We present the first NFT collection on the TON blockchain dedicated to
        the PVP-game «Shaolin Soccer». <br />
        <br />
        Each owner of the NFT will have the <img src={GameController} alt="" />
        <span>
          right to be among the first to join the game and create their own
          unique <br /> football player
        </span>{" "}
        <img src={CurrencyCircleDollar} alt="" />
        <span>or become an investor of the club and earn money from it.</span>
      </p>
    </ScDescription>
  );
}

const device = {
  tablet: `max-width: ${ScreenSize.TABLET}px`,
  mobile: `max-width: ${ScreenSize.MOBILE}px`,
};

const ScDescription = styled.div`
  font-family: "IBM Plex Mono", monospace;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;

  h1,
  h2 {
    font-weight: 700;
    font-size: 51px;
    line-height: 66px;
    color: #4dda36;
    margin: 0;
    text-align: center;

    span {
      color: #fff;
    }
  }

  h2 {
    color: #fff;
    font-size: 32px;
    line-height: 41px;
  }

  p {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #fdfffd;
    margin-top: 40px;

    span {
      margin-left: 5px;
      color: #4dda36;
    }
  }

  @media (${device.tablet}) {
    padding: 0 30px;
    h1 {
      font-size: 38px;
      line-height: 46px;
    }

    h2 {
      font-size: 23px;
      line-height: 29px;
    }

    p {
      font-size: 18px;
      line-height: 28px;
    }
  }
  @media (${device.mobile}) {
    padding: 0 20px;
    h1 {
      font-weight: 700;
      font-size: 25px;
      line-height: 32px;
    }

    h2 {
      font-size: 26px;
      line-height: 34px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-top: 24px;
    }
  }
`;
